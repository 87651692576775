/* eslint-disable no-undef */
/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: 'fr',
    locales: ['fr'],
    saveMissing: true,
    missingKeyHandler: (ng, ns, key, fallbackValue) => {
      console.warn(`[i18n] Key not found ${ng}, ${ns}: "${key}" (${fallbackValue})`);
    },
    serializeConfig: false,
  },
};
