import * as yup from 'yup';

import { DONATIONS_SIZE } from '@/core/lib/constants';
import { hasNotUrl } from '@/core/lib/form/validators';
import { adminResultSchema, cityResultSchema, countryResultSchema, locationFormSchema, pointResultSchema, subResultSchema } from '@/core/types/geo';
import { userResultSchema } from '@/core/types/users';

export const categoryOptionValueResultSchema = yup.object({
  name: yup.string().required(),
  value: yup.string().required(),
});
export type CategoryOptionValueResult = yup.InferType<typeof categoryOptionValueResultSchema>;
export enum DonationState {
  INITIALIZED = 'initialized',
  MODERATION = 'moderation',
  RESENT = 'resent',
  PUBLISHED = 'published',
  RESERVED = 'reserved',
  ENDED = 'ended',
  REFUSED = 'refused',
}

export const publicDonationResultSchema = yup.object({
  id: yup.number().required(),
  title: yup.string().required(),
  cat: yup.number().required(),
  giver: yup.string().required(),
  description: yup.string().nullable(),
  state: yup.mixed<DonationState>().oneOf(Object.values(DonationState)).required(),
  city: yup.number().required(),
  sub: yup.number().nullable(),
  signature: yup.string().nullable(),
  last_published_at: yup.string().nullable(),
  photos: yup.array(yup.string().required()).required(),
  sollicitations: yup.number().nullable(),
  co2: yup.number().required(),
  loc: pointResultSchema.required(),
});

export type PublicDonationResult = yup.InferType<typeof publicDonationResultSchema>;

export const publicDonationItemResultSchema = yup.object({
  is_winner: yup.boolean().required(),
  conversation: yup.number().nullable(),
  new_messages: yup.number().required(),
  can_create_conversation: yup.boolean().required(),
  can_create_conversation_reason: yup.string().nullable(),
  donation: publicDonationResultSchema.required(),
  admins: yup.array(adminResultSchema.required()).nullable(),
  cities: yup.array(cityResultSchema.required()).nullable(),
  countries: yup.array(countryResultSchema.required()).nullable(),
  subs: yup.array(subResultSchema.required()).nullable(),
  users: yup.array(userResultSchema.required()).nullable(),
  options: yup.array(categoryOptionValueResultSchema.required()).nullable(),
});

export type PublicDonationItemResult = yup.InferType<typeof publicDonationItemResultSchema>;

export const privateDonationResultSchema = yup.object({
  id: yup.number().required(),
  title: yup.string().required(),
  cat: yup.number().required(),
  giver: yup.string().required(),
  co2: yup.number().required(),
  description: yup.string().nullable(),
  state: yup.mixed<DonationState>().oneOf(Object.values(DonationState)).required(),
  city: yup.number().required(),
  sub: yup.number().nullable(),
  signature: yup.string().nullable(),
  created_at: yup.string().required(),
  updated_at: yup.string().required(),
  ended_at: yup.string().nullable(),
  first_published_at: yup.string().nullable(),
  last_published_at: yup.string().nullable(),
  paused_at: yup.string().nullable(),
  photos: yup.array(yup.string().required()).required(),
  sollicitations: yup.number().nullable(),
  loc: pointResultSchema.required(),
  can_reset: yup.boolean().required(),
});

export type PrivateDonationResult = yup.InferType<typeof privateDonationResultSchema>;

export const privateDonationItemResultSchema = yup.object({
  new_messages: yup.number().required(),
  reason: yup.string().nullable(),
  donation: privateDonationResultSchema.required(),
  admins: yup.array(adminResultSchema.required()).nullable(),
  cities: yup.array(cityResultSchema.required()).nullable(),
  countries: yup.array(countryResultSchema.required()).nullable(),
  subs: yup.array(subResultSchema.required()).nullable(),
  users: yup.array(userResultSchema.required()).nullable(),
  options: yup.array(categoryOptionValueResultSchema.required()).nullable(),
});

export type PrivateDonationItemResult = yup.InferType<typeof privateDonationItemResultSchema>;

export const donationsPrivateDonationQuerySchema = yup.object({
  title: yup.string().optional(),
  cat: yup.string().optional(),
  states: yup.array(yup.mixed<DonationState>().oneOf(Object.values(DonationState))).optional(),
  from: yup
    .number()
    .optional()
    .when('page', {
      is: (page: number) => !!page,
      then: schema => {
        return schema.default(defaultOptions => {
          const size = defaultOptions?.parent.size ?? DONATIONS_SIZE;
          const page = defaultOptions?.parent.page ?? 1;
          return (page - 1) * size;
        });
      },
      otherwise: schema => schema.default(0),
    }),
  size: yup.number().optional().default(DONATIONS_SIZE),
  page: yup.number().optional().default(1),
});

export type DonationsPrivateDonationQuery = Partial<yup.InferType<typeof donationsPrivateDonationQuerySchema>>;

export const donationsPrivateDonationListResultSchema = yup.object({
  donations: yup.array(privateDonationResultSchema.required()).required(),
  admins: yup.array(adminResultSchema.required()).nullable(),
  cities: yup.array(cityResultSchema.required()).nullable(),
  countries: yup.array(countryResultSchema.required()).nullable(),
  subs: yup.array(subResultSchema.required()).nullable(),
  users: yup.array(userResultSchema.required()).nullable(),
});
export type DonationsPrivateDonationListResult = yup.InferType<typeof donationsPrivateDonationListResultSchema>;

export const donationsUploadFormSchema = yup.object({
  photo: yup.string().required(),
});

export type DonationUploadForm = yup.InferType<typeof donationsUploadFormSchema>;

export const donationsCategoryOptionResultSchema = yup.object({
  cat: yup.number().required(),
  option: yup.string().nullable(),
  is_required: yup.boolean().required(),
});

export type DonationsCategoryOptionResult = yup.InferType<typeof donationsCategoryOptionResultSchema>;

export const donationsCategoryOptionValuesResultSchema = yup.object({
  option: yup.string().required(),
  values: yup.array(yup.string().required()).required(),
});

export type DonationsCategoryOptionValuesResult = yup.InferType<typeof donationsCategoryOptionValuesResultSchema>;

export const donationsCategoryResultSchema = yup.object({
  id: yup.number().required(),
  state: yup.number().required(),
  label: yup.string().required(),
  alias: yup.string().required(),
  parent: yup.number().nullable(),
});

export type DonationsCategoryResult = yup.InferType<typeof donationsCategoryResultSchema>;

export const donationsCategoriesResultSchema = yup.object({
  categories: yup.array(donationsCategoryResultSchema).required(),
  options: yup.array(donationsCategoryOptionResultSchema).required(),
  values: yup.array(donationsCategoryOptionValuesResultSchema).required(),
});

export type DonationsCategoriesResult = yup.InferType<typeof donationsCategoriesResultSchema>;

export enum QuerySortEnum {
  RELEVANCE = 'relevance',
  DISTANCE = 'distance',
  DATE = 'date',
}

export const donationsPublicDonationQuerySchema = yup.object({
  title: yup.string().optional(),
  cat: yup.string().optional(),
  loc: yup.string().optional(),
  distance: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .optional()
    .when('loc', {
      is: (loc: string | undefined) => !!loc,
      then: schema => schema.default(20),
      otherwise: schema => schema,
    }),
  lat: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .optional(),
  lon: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .optional(),
  published_at: yup.string().optional(),
  giver: yup.string().optional(),
  from: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .optional()
    .when('page', {
      is: (page: number) => !!page,
      then: schema => {
        return schema.default(defaultOptions => {
          const size = defaultOptions?.parent.size ?? DONATIONS_SIZE;
          const page = defaultOptions?.parent.page ?? 1;
          return (page - 1) * size;
        });
      },
      otherwise: schema => schema.default(0),
    }),
  size: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .optional()
    .default(DONATIONS_SIZE),
  sort: yup
    .mixed<QuerySortEnum>()
    .oneOf(Object.values(QuerySortEnum))
    .when('title', {
      is: (title: string | undefined) => !!title,
      then: schema => schema.default(QuerySortEnum.RELEVANCE),
      otherwise: schema => schema.default(QuerySortEnum.DATE),
    }),
  page: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .optional()
    .default(1),
});

export type DonationsPublicDonationQuery = Partial<yup.InferType<typeof donationsPublicDonationQuerySchema>>;

export const donationsPublicDonationListResultSchema = yup.object({
  hash: yup.string().nullable(),
  total: yup.number().required(),
  took: yup.number().required(),
  donations: yup.array(publicDonationResultSchema.required()).required(),
  admins: yup.array(adminResultSchema.required()).nullable(),
  cities: yup.array(cityResultSchema.required()).nullable(),
  countries: yup.array(countryResultSchema.required()).nullable(),
  subs: yup.array(subResultSchema.required()).nullable(),
  users: yup.array(userResultSchema.required()).nullable(),
});

export type DonationsPublicDonationListResult = yup.InferType<typeof donationsPublicDonationListResultSchema>;

export const donationsPublicDonationCountResultSchema = yup.object({
  total: yup.number().required(),
});

export type DonationsPublicDonationCountResult = yup.InferType<typeof donationsPublicDonationCountResultSchema>;

export const donationsDonationFormSchema = yup.object({
  title: yup.string().test('has_not_url', 'errors.inputs.has_not_url_sentence', hasNotUrl).required('errors.inputs.required'),
  category: donationsCategoryResultSchema.required('errors.inputs.required'),
  description: yup.string().max(1000, 'errors.inputs.max-1000').test('has_not_url', 'errors.inputs.has_not_url_sentence', hasNotUrl).nullable(),
  location: locationFormSchema.required('errors.inputs.required'),
  signature: yup.string().test('has_not_url', 'errors.inputs.has_not_url_sentence', hasNotUrl).nullable().max(400, 'errors.inputs.max-400'),
  photos: yup.array(yup.string().required('errors.inputs.required')).required('errors.inputs.required'),
  clothes: yup.string().nullable(),
  shoes: yup.string().nullable(),
  bra: yup.string().nullable(),
  fashion_accessory: yup.string().nullable(),
  state_option: yup.string().nullable(),
  dlc: yup.string().nullable(),
});

export type DonationDonationForm = yup.InferType<typeof donationsDonationFormSchema>;

export enum DonationDeleteReason {
  GIVE_DONNONS = 'give_donnons',
  GIVE_ELSE = 'give_else',
  NOT_INTERESTED = 'not_interested',
  CHANGED_MIND = 'changed_mind',
  SELL_ELSE = 'sell_else',
}

export const donationDeleteFormSchema = yup.object({
  reason: yup.mixed<DonationDeleteReason>().oneOf(Object.values(DonationDeleteReason)).required(),
  taker: yup.string().optional(),
});
export type DonationDeleteFormType = yup.InferType<typeof donationDeleteFormSchema>;

export const donationsDonationApiFormSchema = yup.object({
  title: yup.string().test('has_not_url', 'errors.inputs.has_not_url_sentence', hasNotUrl).required('errors.inputs.required'),
  cat: yup.number().required('errors.inputs.required'),
  description: yup.string().max(1000, 'errors.inputs.max-1000').test('has_not_url', 'errors.inputs.has_not_url_sentence', hasNotUrl).nullable(),
  loc: yup.string().required('errors.inputs.required'),
  lat: yup.number().optional(),
  lon: yup.number().optional(),
  signature: yup.string().test('has_not_url', 'errors.inputs.has_not_url_sentence', hasNotUrl).nullable().max(400, 'errors.inputs.max-400'),
  photos: yup.array(yup.string().required('errors.inputs.required')).required('errors.inputs.required'),
  clothes: yup.string().nullable(),
  shoes: yup.string().nullable(),
  bra: yup.string().nullable(),
  fashion_accessory: yup.string().nullable(),
  state_option: yup.string().nullable(),
  dlc: yup.string().nullable(),
});

export type DonationDonationApiForm = yup.InferType<typeof donationsDonationApiFormSchema>;

export const donationsNotationFormSchema = yup.object({
  is_nice: yup.boolean().nullable(),
  is_punctual: yup.boolean().nullable(),
});

export type DonationsNotationForm = yup.InferType<typeof donationsNotationFormSchema>;

export const reportsReportFormSchemaDonation = yup.object({
  at_least_one_checked: yup.string().test('at_least_one_checked', 'errors.inputs.at_least_one_checked', function oneChecked() {
    return this.parent.donation_error || this.parent.donation_danger || this.parent.donation_scam || this.parent.donation_other;
  }),
  donation_error: yup.boolean(),
  donation_danger: yup.boolean(),
  donation_scam: yup.boolean(),
  donation_other: yup.boolean(),
  comment: yup.string().when('donation_other', {
    is: true,
    then: schema => schema.required('errors.inputs.details').max(400, 'errors.inputs.max-400'),
  }),
});

export type ReportsReportFormDonation = yup.InferType<typeof reportsReportFormSchemaDonation>;
