import * as yup from 'yup';

export const pointResultSchema = yup.object({
  lat: yup.number().required(),
  lon: yup.number().required(),
});

export type PointResult = yup.InferType<typeof pointResultSchema>;

export const countryResultSchema = yup.object({
  id: yup.string().required(),
  alias: yup.string().required(),
  name: yup.string().required(),
});

export type CountryResult = yup.InferType<typeof countryResultSchema>;

export const adminResultSchema = yup.object({
  id: yup.string().required(),
  alias: yup.string().required(),
  name: yup.string().required(),
  country: yup.string().nullable(),
  parent: yup.string().nullable(),
  type: yup.string().nullable(),
  level: yup.number().nullable(),
  order: yup.number().nullable(),
});

export type AdminResult = yup.InferType<typeof adminResultSchema>;

export const cityResultSchema = yup.object({
  id: yup.number().required(),
  alias: yup.string().required(),
  name: yup.string().required(),
  country: yup.string().optional(),
  admin: yup.string().nullable(),
  loc: pointResultSchema.required(),
  ref_id: yup.string().nullable(),
  ref_type: yup.string().nullable(),
});

export type CityResult = yup.InferType<typeof cityResultSchema>;

export const subResultSchema = yup.object({
  id: yup.number().required(),
  alias: yup.string().required(),
  name: yup.string().required(),
  country: yup.string().required(),
  admin: yup.string().nullable(),
  city: yup.number().required(),
  loc: pointResultSchema.optional().nullable(),
});

export type SubResult = yup.InferType<typeof subResultSchema>;

const locCityResultSchema = yup.object({
  id: yup.number().required(),
  alias: yup.string().required(),
  name: yup.string().required(),
  ref_id: yup.string().nullable(),
  ref_type: yup.string().nullable(),
});

export const locResultSchema = yup.object({
  id: yup.string().required(),
  type: yup.number().optional(),
  proximity: yup.boolean().optional().nullable(),
  country: countryResultSchema.required(),
  admin: adminResultSchema.optional().nullable(),
  city: locCityResultSchema.optional().nullable(),
  sub: subResultSchema.optional().nullable(),
  location: pointResultSchema.optional().nullable(),
});

export type LocResult = yup.InferType<typeof locResultSchema>;

export const geoResultSchema = yup.object({
  string: yup.string().optional(),
  results: yup.array(locResultSchema).optional(),
});

export type GeoResult = yup.InferType<typeof geoResultSchema>;

export const geoDetailResultSchema = yup.object({
  search: yup.string().optional(),
  result: locResultSchema.required(),
});

export type GeoDetailResult = yup.InferType<typeof geoDetailResultSchema>;

export const reverseGeoResultSchema = yup.object({
  result: locResultSchema.required(),
});

export type ReverseGeoResult = yup.InferType<typeof reverseGeoResultSchema>;

export const locationFormSchema = yup.object({
  loc: yup.string().required('errors.inputs.required'),
  lat: yup.number().optional(),
  lon: yup.number().optional(),
  distance: yup.number().optional(),
  label: yup.string().optional(),
  refId: yup.string().optional(),
});

export type LocationForm = yup.InferType<typeof locationFormSchema>;
