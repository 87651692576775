import omit from 'lodash/omit';

import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import type { DonationDeleteFormType, DonationDonationApiForm, DonationDonationForm, DonationsNotationForm, PrivateDonationItemResult, PublicDonationItemResult } from '@/core/types/donation';
import { privateDonationItemResultSchema, publicDonationItemResultSchema } from '@/core/types/donation';
import { type UploadResult, uploadResultSchema } from '@/core/types/donnons';
import { type UsersUsersResult, usersUsersResultSchema } from '@/core/types/users';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const query = {
  get: async (id: number, token?: string) =>
    api.get<PublicDonationItemResult>({
      url: `${getUrl()}${ApiVersions.V1}/donation/${id}`,
      schema: publicDonationItemResultSchema,
      token,
    }),
  takers: async (id: number, token: string) =>
    api.get<UsersUsersResult>({
      url: `${getUrl()}${ApiVersions.V1}/donation/${id}/takers`,
      schema: usersUsersResultSchema,
      token,
    }),
};

export enum DonationDeleteReason {
  GIVE_ELSE = 'give_else',
  NOT_INTERESTED = 'not_interested',
  CHANGED_MIND = 'changed_mind',
  SELL_ELSE = 'sell_else',
  GIVE_DONNONS = 'give_donnons',
}

const mutation = {
  create: async (data: DonationDonationForm, token?: string) =>
    api.post<PrivateDonationItemResult, DonationDonationApiForm>({
      url: `${getUrl()}${ApiVersions.V1}/donation`,
      schema: privateDonationItemResultSchema,
      data: { ...omit(data, 'location'), cat: data.category.id, loc: data.location.loc!, lat: data.location.lat, lon: data.location.lon },
      token,
    }),
  notation: async (notationId: number, data: DonationsNotationForm, token?: string) =>
    api.patch<void, DonationsNotationForm>({ url: `${getUrl()}${ApiVersions.V1}/donation/notation/${notationId}`, data, token }),
  upload: async (data: FormData, token?: string) => api.image<UploadResult>({ url: `${getUrl()}${ApiVersions.V1}/donation/photo`, schema: uploadResultSchema, data, token }),
  reserve: async (data: { donationId: number; takerUuid: string }, token?: string) =>
    api.patch<void, void>({ url: `${getUrl()}${ApiVersions.V1}/donation/reserved/${data.donationId}/${data.takerUuid}`, data: undefined, token }),
  unreserve: async (donationId: number, token?: string) => api.patch<void, void>({ url: `${getUrl()}${ApiVersions.V1}/donation/unreserved/${donationId}`, data: undefined, token }),
  terminate: async (id: number, data: DonationDeleteFormType, token?: string) =>
    api.destroy({ url: `${getUrl()}${ApiVersions.V1}/donation/${id}?reason=${data.reason}${data.taker ? `&taker=${data.taker}` : ''}`, token, data: undefined }),
  modify: async (id: number, data: DonationDonationForm, token?: string) =>
    api.put<PrivateDonationItemResult, Partial<DonationDonationApiForm>>({
      url: `${getUrl()}${ApiVersions.V1}/donation/${id}`,
      schema: privateDonationItemResultSchema,
      data: { ...omit(data, 'location'), cat: data.category.id, loc: data.location.loc!, lat: data.location.lat, lon: data.location.lon },
      token,
    }),
  lock: async (id: number, token?: string) => api.post({ url: `${getUrl()}${ApiVersions.V1}/donation/${id}/lock`, data: undefined, token }),
  unlock: async (id: number, token?: string) => api.destroy({ url: `${getUrl()}${ApiVersions.V1}/donation/${id}/lock`, data: undefined, token }),
  reactivate: async (donationId: number, token?: string) => api.patch<void, void>({ url: `${getUrl()}${ApiVersions.V1}/donation/reset/${donationId}`, data: undefined, token }),
};

export default { query, mutation };
