import useMutation from '@/core/hooks/api/useMutation';
import type { APIError } from '@/core/lib/fetch';
import Api from '@/core/lib/new-architecture/api';
import Query from '@/core/lib/new-architecture/query';
import getToken from '@/core/lib/new-architecture/query/token';
import Store from '@/core/lib/new-architecture/store';
import type { DonationDeleteFormType, DonationDonationForm, PrivateDonationItemResult } from '@/core/types/donation';

type AwaitedCreate = Awaited<ReturnType<typeof Api.mutation.donation.create>>;

const useReactivate = () => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate: invalidateDonation } = Query.donation.useInvalidate();
  const { invalidate: invalidateDonations } = Query.donations.useInvalidate();
  const { invalidate: invalidateUserDonations } = Query.user.donations.useInvalidate();
  const { invalidate: invalidateUserDonation } = Query.user.donation.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.donation.unreserve>>, APIError, number>({
    mutationFn: async (donationId: number) => Api.mutation.donation.reactivate(donationId, await getToken(requestToken)),
    onSuccess: async () => {
      // refresh is to fast to have the user donation without reactivate donation so we have to timeout
      setTimeout(async () => {
        Promise.all([invalidateDonation(), invalidateDonations(), invalidateUserDonations(), invalidateUserDonation()]);
      }, 200);
    },
  });

  const onReactivate = async (donationId: number) => {
    try {
      await mutation.mutateAsync(donationId);
    } catch (err) {
      throw err as APIError;
    }
  };

  return { onReactivate, isLoading: mutation.isPending };
};

const useCreateDonation = () => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidateAfterCreateOrUpdateDonation } = Query.user.donation.useInvalidateAfterCreateOrUpdateDonation();

  const mutation = useMutation<AwaitedCreate, APIError, DonationDonationForm>({
    mutationFn: async (data: DonationDonationForm) => Api.mutation.donation.create(data, await getToken(requestToken)),
    onSuccess: async ({ donation }) => invalidateAfterCreateOrUpdateDonation(donation.id),
  });

  const onCreate = async (data: DonationDonationForm) => mutation.mutateAsync(data);

  return { onCreate, isLoading: mutation.isPending };
};

type AwaitedModify = Awaited<ReturnType<typeof Api.mutation.donation.modify>>;

const useModifyDonation = (donation?: PrivateDonationItemResult) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidateAfterCreateOrUpdateDonation } = Query.user.donation.useInvalidateAfterCreateOrUpdateDonation();

  const mutation = useMutation<AwaitedModify, APIError, DonationDonationForm>({
    mutationFn: async (data: DonationDonationForm) => Api.mutation.donation.modify(donation?.donation.id as number, data, await getToken(requestToken)),
    onSuccess: async ({ donation: updatedDonation }) => invalidateAfterCreateOrUpdateDonation(updatedDonation.id),
  });

  const onModify = async (data: DonationDonationForm) => mutation.mutateAsync(data);

  return { onModify, isLoading: mutation.isPending };
};

type AwaitedTerminate = Awaited<ReturnType<typeof Api.mutation.donation.terminate>>;

const useTerminateDonation = (donationId: number) => {
  const { requestToken } = Store.auth.useRequest();

  const { invalidateAfterDeleteDonation } = Query.user.donation.useInvalidateAfterDeleteDonation();

  const mutation = useMutation<AwaitedTerminate, APIError, DonationDeleteFormType>({
    mutationFn: async (data: DonationDeleteFormType) => Api.mutation.donation.terminate(donationId, data, await getToken(requestToken)),
    onSuccess: async () => invalidateAfterDeleteDonation(),
  });

  const onDelete = async (data: DonationDeleteFormType) => mutation.mutateAsync(data);
  return { onDelete };
};
export { useCreateDonation, useModifyDonation, useReactivate, useTerminateDonation };
