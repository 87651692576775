import '@/core/theme/tailwind.css';

import type { AppProps } from 'next/app';
import { Abril_Fatface, Poppins } from 'next/font/google';
import { appWithTranslation } from 'next-i18next';
import nextI18nextConfig from 'next-i18next.config';
import React from 'react';

import * as CatalogueSearch from '@/core/components/CatalogueSearch';
import { ErrorBoundary, ErrorBoundaryFallback } from '@/core/components/errors';
import HeadComponent from '@/core/components/Head';
import UserControl from '@/core/components/UserControl';
import AuthProvider from '@/core/lib/auth/auth.context';
import BrowserProvider from '@/core/lib/browser/browser.context';
import start from '@/core/lib/bugsnag';
import DocumentProvider from '@/core/lib/document/document.context';
import EnvProvider from '@/core/lib/env/env.context';
import Actions from '@/core/lib/new-architecture/actions';
import Context from '@/core/lib/new-architecture/context';
import SSRProvider from '@/core/lib/new-architecture/context/ssr.context';
import QueryProvider from '@/core/lib/query/query.context';
import ScrollProvider from '@/core/lib/scroll/scroll.context';
import Authentification from '@/core/pages/auth/Authentification';
import CreateDonation from '@/core/pages/donation/CreateDonation';
import type { PageWithLayoutsAndProviders } from '@/core/pages/page.types';
import NextLayout from '@/next/layouts/NextLayout';
import AdsProvider from '@/next/lib/ads/AdsProvider.next';
import AnalyticsProvider from '@/next/lib/analytics/AnalyticsProvider.next';
import ComponentsProvider from '@/next/lib/components/ComponentsProvider.next';
import FFProvider from '@/next/lib/ff/FFProvider.next';
import GeolocationProvider from '@/next/lib/geolocation/GeolocationProvider.next';
import NativeProvider from '@/next/lib/native/NativeProvider.next';
import NotificationProvider from '@/next/lib/notification/NotificationProvider.next';
import PersistProvider from '@/next/lib/persist/PersistProvider.next';
import RouterProvider from '@/next/lib/router/RouterProvider.next';
import ShareProvider from '@/next/lib/share/ShareProvider.next';
import ToastProvider from '@/next/lib/toast/ToastProvider.next';
import TranslationProvider from '@/next/lib/translation/TranslationProvider.next';

start();

const poppins = Poppins({
  weight: ['400', '500', '600'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-poppins',
});

const abril = Abril_Fatface({
  weight: '400',
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-abril',
});

export type AppPropsWithLayout = AppProps & {
  Component: PageWithLayoutsAndProviders;
};

const NextApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getProviders = Component.getProviders ?? (page => page);
  const getLayouts = Component.getLayouts ?? (page => page);
  const { footer } = Component;

  return (
    <div className={`${abril.variable}`}>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>{`
        html {
          font-family: ${poppins.style.fontFamily}, sans-serif;
        }
      `}</style>

      <SSRProvider pageProps={pageProps}>
        <PersistProvider>
          <NotificationProvider>
            <QueryProvider dehydratedState={pageProps.dehydratedState}>
              <Context.ContextProvider>
                <RouterProvider>
                  <Actions.AppWideActions>
                    <EnvProvider>
                      <DocumentProvider>
                        <NativeProvider>
                          <ScrollProvider>
                            <ComponentsProvider>
                              <TranslationProvider>
                                <AnalyticsProvider>
                                  <GeolocationProvider>
                                    <AuthProvider>
                                      <BrowserProvider>
                                        <ToastProvider>
                                          <ShareProvider>
                                            <FFProvider>
                                              <AdsProvider>
                                                <CatalogueSearch.CatalogueSearchProvider>
                                                  <HeadComponent />
                                                  <NextLayout footer={footer}>
                                                    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                                                      {getProviders(getLayouts(<Component {...pageProps} />))}
                                                      <Authentification />
                                                      <UserControl />
                                                      <CreateDonation />
                                                    </ErrorBoundary>
                                                  </NextLayout>
                                                </CatalogueSearch.CatalogueSearchProvider>
                                              </AdsProvider>
                                            </FFProvider>
                                          </ShareProvider>
                                        </ToastProvider>
                                      </BrowserProvider>
                                    </AuthProvider>
                                  </GeolocationProvider>
                                </AnalyticsProvider>
                              </TranslationProvider>
                            </ComponentsProvider>
                          </ScrollProvider>
                        </NativeProvider>
                      </DocumentProvider>
                    </EnvProvider>
                  </Actions.AppWideActions>
                </RouterProvider>
              </Context.ContextProvider>
            </QueryProvider>
          </NotificationProvider>
        </PersistProvider>
      </SSRProvider>
    </div>
  );
};

export default appWithTranslation(NextApp, nextI18nextConfig);
