import React from 'react';

interface NotifBadgeProps {
  unread: number;
  size: 'large' | 'small';
  isEmpty?: boolean;
}

const NotifBadge: React.FC<NotifBadgeProps> = ({ unread, size, isEmpty }) => {
  if (isEmpty) {
    return <div className={`inline-flex ${size === 'large' ? 'size-4 lg:size-5' : 'size-3'} items-center justify-center rounded-3 bg-[#E83912] px-1`} />;
  }

  let unreadString = null;

  if (unread > 0 && unread < 10) {
    unreadString = unread.toString();
  } else if (unread > 9) {
    unreadString = '9+';
  }

  if (!unreadString) {
    return null;
  }

  return (
    <div className={`inline-flex ${size === 'large' ? 'size-4 lg:size-5' : 'size-3'} items-center justify-center rounded-3 bg-[#E83912] px-1`}>
      <span className={`${size === 'large' ? 'text-12' : 'text-8'} pl-[1px] pt-[1px] font-bold text-content-button-primary`}>{unreadString}</span>
    </div>
  );
};

export default NotifBadge;
